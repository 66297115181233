<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">数据统计</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">合规自查</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl searchboxfl">
          <div class="searchbox" style="margin-bottom:15px">
              <div
              title="学员搜索"
              style="display: flex; align-items: center"
              class="searchboxItem ci-full"
            >
              <span class="itemLabel">学员搜索:</span>
              <el-select
                v-model="userId"
                placeholder="请选择"
                no-data-text="没有数据"
                remote
                size="small"
                clearable
                :remote-method="superUserSelect"
                @visible-change="clearUserSearchModel"
              >
                <template>
                  <div class="select-header">
                    <span
                      style="
                        color: #8492a6;
                        float: left;
                        font-size: 13px;
                        width: 100px;
                      "
                      >学员姓名</span
                    >
                    <span
                      style="
                        color: #8492a6;
                        float: left;
                        font-size: 13px;
                        margin-left: 50px;
                        width: 140px;
                      "
                      >身份证号码</span
                    >
                    <span
                      style="
                        color: #8492a6;
                        float: left;
                        font-size: 13px;
                        margin-left: 50px;
                        width: 100px;
                      "
                      >学员电话</span
                    >
                  </div>
                  <div class="select-header">
                    <el-input
                      v-model="seaUserName"
                      v-on:input="superUserSelect"
                      type="text"
                      size="small"
                      placeholder="学员姓名"
                      clearable
                      style="width: 100px"
                    />
                    <el-input
                      v-model="seaUserIdcard"
                      v-on:input="superUserSelect"
                      type="text"
                      size="small"
                      placeholder="身份证号码"
                      clearable
                      style="margin-left: 50px; width: 140px"
                    />
                    <el-input
                      v-model="seaUserMobile"
                      v-on:input="superUserSelect"
                      type="text"
                      size="small"
                      placeholder="学员电话"
                      clearable
                      style="margin-left: 50px; width: 100px"
                    />
                  </div>
                </template>
                <el-option
                  v-for="(item,index) in userList"
                  :key="index"
                  :label="item.userName"
                  :value="item.userId"
                >
                  <span style="width: 100px; font-size: 13px">{{
                    item.userName
                  }}</span>
                  <span
                    style="width: 140px; margin-left: 50px; font-size: 13px"
                    >{{ item.idcard }}</span
                  >
                  <span
                    style="width: 100px; margin-left: 50px; font-size: 13px"
                    >{{ item.mobile }}</span
                  >
                </el-option>
              </el-select>
            </div>
            <div title="培训机构名称" class="searchboxItem ci-full">
              <span class="itemLabel">机构名称:</span>
              <el-input
                v-model="compName"
                type="text"
                size="small"
                placeholder="请输入培训机构名称"
                clearable
              />
            </div>
            <span title="培训类型" class="searchboxItem ci-full flexcc">
              <span
                class="itemLabel"
                style="width:95px; font-size:14px; padding:0 0.5rem"
                >培训类型:</span
              >
              <tree
                ref="tree"
                @eventBtn="childBack"
                :ruleForm="ruleForm"
                size="small"
                @clearParams="clearParams"
                modal
                typeStu
              />
            </span>
            <div title="行政区划" class="searchboxItem ci-full">
              <span class="itemLabel">行政区划:</span>
              <el-cascader
                :options="areatreeList"
                clearable
                filterable
                :props="propsarea"
                size="small"
                v-model="areaId"
              ></el-cascader>
            </div>
           
          </div>

          <div class="searchbox">
           
            
            <div title="抽检时间" class="searchboxItem ci-full">
              <span class="itemLabel">抽检时间:</span>
              <el-date-picker
                clearable
                size="small"
                v-model="endTime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
 format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
              ></el-date-picker>
            </div>
            <div title="达标率" class="searchboxItem ci-full">
              <span class="itemLabel">达标率:</span>
               <el-input placeholder="请输入达标率" v-model="controlRates"  size="small" onkeyup="value=value.replace(/\D|^0/g,'')" >
    <template slot="append">%</template>
  </el-input>
            </div>
            <el-button type="primary" class="bgc-bv" round @click="getData()"
              >查询</el-button
            >
          </div>
        </div>

        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                fixed
                :index="indexMethod"
                width="100px"
              />
              <el-table-column
                label="学员姓名"
                align="left"
                show-overflow-tooltip
                prop="userName"
                fixed
              />
              <el-table-column
                label="身份证号"
                align="left"
                show-overflow-tooltip
                prop="idcard"
                width="200px"
              />
              <el-table-column
                label="手机号码"
                align="left"
                show-overflow-tooltip
                prop="mobile"
                width="120px"
              />
              <el-table-column
                label="机构名称"
                align="left"
                show-overflow-tooltip
                prop="compName"
                width="260px"
              />
              <el-table-column
                label="班级编号"
                align="left"
                show-overflow-tooltip
                prop="projectCode"
                width="180px"
              />
              <el-table-column
                label="班级名称"
                align="left"
                show-overflow-tooltip
                prop="projectName"
                width="180px"
              />
              <el-table-column
                label="课程名称"
                width="180px"
                align="left"
                prop="courseName"
                show-overflow-tooltip
              />
               
              <el-table-column
                label="课件名称"
                min-width="220px"
                align="left"
                prop="kpointName"
                show-overflow-tooltip
              />
              <el-table-column
                label="学习视频时长"
                align="left"
                show-overflow-tooltip
                 min-width="220px"
                prop="kpointDuration"
              >
              <template slot-scope="scope">
                  {{getTime(scope.row.kpointDuration)}}
              </template>
              </el-table-column>
              <el-table-column
                label="累计视频时长"
                align="left"
                show-overflow-tooltip
                prop="totalTime"
                width="120"
              >
              <template slot-scope="scope">
                  {{getTime(scope.row.totalTime)}}
              </template>
              </el-table-column>
              <el-table-column
                label="达标率"
                align="left"
                show-overflow-tooltip
                prop="controlRates"
              >
                <template slot-scope="scope">
                 <span>{{ scope.row.controlRates + "%" }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="抽检时间"
                align="right"
                show-overflow-tooltip
                prop="createTime"
                min-width="180"
              >
              <template slot-scope="scope">
                  {{scope.row.createTime | momentDate}}
              </template>
              </el-table-column>
               <el-table-column
              label="操作"
              width="100px"
              fixed="right"
              align="center"
            >
              <div slot-scope="scope">
                <el-button
                  type="text"
                  size="mini"
                  style="padding: 0 5px"
                  @click="RemoveAlert(scope.row.trackCheckId)"
                  >移除预警</el-button
                >
               
              </div>
            </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import tree from "@/components/treePopup";
import List from "@/mixins/List";
import moment from "moment";
import { resetKeepAlive } from "@/utils/common";
import { mapGetters } from "vuex";
export default {
  name: "operate/classStart",
  components: {
    Empty,
    PageNum,
    tree,
  },
  mixins: [List],
  data() {
    return {
      areatreeList: [], // 行政区划
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
      areaId: "", //行政区划
      compName: "", //机构名称
      endTime: '', //结束时间
      ruleForm: {
        Trainingtype: "", // 培训类型
      },
      params: {},
      userId: "",
       seaUserName: "",
      seaUserIdcard: "",
      seaUserMobile: "",
  userList: [{}],
  controlRates:'',
    };
  },
  created() {
    this.getareatree();
    this.superUserSelect()
    // this.getCrowdtypeList();
  },
 computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
      userJson: "getUser",
    }),
  },
  mounted() {
    this.getTableHeight();
  },
  methods: {
      superUserSelect(e) {
      this.$post(
        "/biz/user/superUserSelect",
        {
          pageNum: 1, //每次都只要第一页
          pageSize: 5, //根据次参数控制显示的数据行数，尽量不出现滚动条
          userName: this.seaUserName,
          idcard: this.seaUserIdcard,
          mobile: this.seaUserMobile,
          projectId: this.projectId,
        },
        3000,
        false
      ).then((res) => {
        if (res.data.list.length === 0) {
          this.userList = [{}];
        } else {
          this.userList = res.data.list;
        }
      });
    },
    clearUserSearchModel(e) {
      if (e) {
        this.seaUserName = "";
        this.seaUserIdcard = "";
        this.seaUserMobile = "";
        this.superUserSelect();
      }
    },
    childBack(params) {
      this.params = { ...params };
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 4) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
    clearParams() {
      this.params = {};
    },
    getareatree() {
      this.$post("/sys/area/tree").then((ret) => {
        this.areatreeList = ret.data;
      });
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.userId) {
        params.userId = this.userId;
      }
      if (this.compName) {
        params.compName = this.compName;
      }
      if (this.areaId) {
        params.areaId = this.areaId;
      }
      if (this.controlRates) {
        params.controlRates = this.controlRates;
      }
      if (this.params.trainFormId) {
        params.trainTypeId = this.params.trainFormId;
      }
      if (this.params.levelFormId) {
        params.trainLevelId = this.params.levelFormId;
      }
      if (this.params.occFormId) {
        params.occupationId = this.params.occFormId;
      }
      if (this.params.postFormId) {
        params.postId = this.params.postFormId;
      }
      if (this.params.industryFormId) {
        params.industryId = this.params.industryFormId;
      }
      if (this.endTime) {
        params.checkDateStart = this.endTime[0];
        params.checkDateEnd = this.endTime[1];
      }
      this.doFetch({
        url: "/biz/track-check/listPage",
        params,
        pageNum,
      });
    },
     // 转换视频时间
    getTime(val) {
      if (!val) {
        return "0秒";
      }
      let h = parseInt(val / 60 / 60);
      let m = parseInt((val % 3600) / 60);
      let s = val % 60;
      if (h < 9) {
        h = "0" + h;
      }
      if (m < 9) {
        m = "0" + m;
      }
      if (s < 9) {
        s = "0" + s;
      }
      return (
        (h != "00" ? h + "时" : "") +
          (m != "00" ? m + "分" : "") +
          (s != "00" ? s + "秒" : "") || ""
      );
    },
    RemoveAlert(trackCheckId) {
        this.doDel({
        url: "/biz/track-check/remove",
        msg: "确定移除该学员？",
        ps: {
          type: "post",
          data: { trackCheckId },
        },
      });
    }
  },
  beforeRouteLeave: resetKeepAlive,
};
</script>
<style lang="less" scope>
.select-header {
  font-size: 14px;
  padding: 0 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #606266;
  height: 34px;
  line-height: 34px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
}
.searchboxfl {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start !important;
}
.confirmButtonClass {
  color: #fff !important;
  background-color: #5c6be8 !important;
  border-color: #5c6be8 !important;
}
</style>
